import React, {useState, useEffect} from "react"
import { getAllProjects, getOneProject } from "../../services/projects"
import './Projects.css'


export default function Projects() {
    const [projectList, postProjects] = useState([])
    const [project, selectProject] = useState({
        name: "",
        details: "", 
        image: "",
        link: ""
    })

    const getProjects = async () => {
        const data = await getAllProjects()
        postProjects(data)
    }

    useEffect(() => {
        getProjects()
    }, [])

   const getDetails = async (id) => {
    const data = await getOneProject(id)
    selectProject({
        name: data.name,
        details: data.details,
        image: data.image,
        link: data.link
    })
    }

    const resetState = () => {
        selectProject({
            name: "",
            details: "", 
            image: "",
            link: ""
        })
    }

    return(
        <>
        <h2>Jake's Projects</h2>
        <div className="project-map">  
            { project.name !== "" ?  
            <div className="project-detail">
                <h2>{project.name}</h2>
                <img src={project.image} alt="#"/>
                <p>{project.details}</p>
                <a href={project.link} target="_blank" rel="noopener noreferrer"><h3>Check it Out!</h3></a>
                <a href="#projects" onClick={resetState}><h3>Return to Projects</h3></a>
                </div>
            :
            projectList.map((project) => (
                <div className="project-card">
                    <div className="overlay"><button onClick={(e) =>{e.preventDefault(getDetails(project.id))}}>View</button></div>
                <img src={project.image} alt="#"/><p>{project.name}</p>
                </div>
            ))
        }
        </div>
        </>
    )
}