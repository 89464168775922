import React, { Component } from "react"
import "./About.css"

class About extends Component {
    render() {
        return (
            <div className="about">
                <div className="words">
                    <h2>About Me</h2>
                    <h3>Software engineer with a proven ability to be an effective communicator as a member of a team or as a team leader. While directing a team of 4 developers, I outlined daily objectives and tasks suited to each members’ strengths to ensure their growth and success.</h3>
                    <h3>Thanks to my prior career in music with composition, I seamlessly shifted into working closely with clients to code their needs in order to deliver a quality product through full stack experience. My passion for coding drove me to complete a 1,000+ hour coding bootcamp focused on Javascript, React, Ruby on Rails, Python, and SQL to strengthen my overall coding acumen.</h3>
                    <h3>I am motivated to grow and nurture these passions while working within a collaborative, talented development team focused on developing high-quality solutions to meaningful problems.</h3>
                </div>
                <a href="https://res.cloudinary.com/dkhiieq9p/image/upload/v1605292995/Jake_Pressman_-_Official_Resume_thmnun.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "black" }}><img className="resume" src="https://res.cloudinary.com/dkhiieq9p/image/upload/v1605293356/Jake_Pressman_-_Official_Resume_cmuxaf.png" alt="resume" /><p>Click Here to View My Resume</p></a>
            </div>
        )
    }
}

export default About