import React from 'react';
import './App.css';
import Header from './components/Header/Header';
import About from './components/About/About';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <>
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
      <div className="sun">
      </div>
      <nav className="navigation">
        <ul>
          <li><a href="#header">Welcome</a></li>
          <li><a href="#main">About</a></li>
          <li><a href="#projects">Work</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><a href="#footer">Socials</a></li>
        </ul>
      </nav>
      <header id="header">
        <Header />
      </header>
      <main id="main">
        <About />
      </main>
      <section id="projects">
        <Projects />
      </section>
      <section id="contact">
        <Contact />
      </section>
      <footer id="footer">
        <Footer />
      </footer>
    </>
  );
}

export default App;
