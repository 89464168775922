import React, { Component } from "react"
import "./Header.css"

class Header extends Component {

    render() {
        return(
            <div className="intro">
            <img src="https://res.cloudinary.com/dkhiieq9p/image/upload/v1594916168/Screen_Shot_2020-07-16_at_12.16.02_PM_vf5rzz.png" alt="profile pic"></img>
            <div className="title">
            <h1>Jake Pressman</h1>
            <h2>Software Engineer • Web Developer</h2>
            </div>
            </div>
        )
    }
}

export default Header