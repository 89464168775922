import React, { Component } from "react"
import "./Footer.css"

class Footer extends Component{

    render(){
        return(
            <div className="footer">
            <nav>
                <ul>
                    <li><a href="https://www.linkedin.com/in/jakepressman/"><img src="https://res.cloudinary.com/dkhiieq9p/image/upload/v1598539090/linkedin-logo_fmvvfw.png" alt="#"/></a></li>
                    <li><a href="https://github.com/jpress7639"><img src="https://res.cloudinary.com/dkhiieq9p/image/upload/v1598539224/kisspng-computer-icons-logo-portable-network-graphics-clip-icons-for-free-iconza-circle-social-5b7fe46b4ec817.2080142615351082033227_ga4alh.png" alt="#"/></a></li>
                </ul>
            </nav>
            </div>
        )
    }
}

export default Footer